<template>
  <div class="caseProjectDetail">
    <div class="top">
      <div class="topBar">
        <div class="titleBox">
          <div class="back" @click='back'>
            <div class="iconfont iconlfArrow"></div>
            <div class="text">返回</div>
          </div>
          <div class="lineBar"></div>
          <div class="title" v-loading='caseSelectLoading'>
            <el-select 
            class='titleSelect'
             size='mini'
            v-model="form.caseStatus"
            @change='caseStatusChange'
            >
              <el-option
                v-for="item in caseStatusOptions"
                :key="item.key"
                :label="item.value"
                :value="item.key">
              </el-option>
            </el-select>
            <div class="titleText">{{caseInfo.caseName}}</div>
            <!-- 立项、立案 -->
            <div class="titleApple"
            v-if='form.applyInfo&&form.applyInfo.applyStatusName'
            @click='applyDetils'>{{isProject?'查看立项审批':'查看立案审批'}}（{{form.applyInfo&&form.applyInfo.applyStatusName&&form.applyInfo.applyStatusName}}）</div>
          </div>
        </div>
        <div class="btnBox">
          <myPopover >
            <template v-slot:toggle>
              <div class="iconfont icongengduo"></div>
            </template>
            <div class="list">
              <!-- <div>复制</div> -->
              <div @click='createCaseOrProject' v-if='!(form.applyInfo&&form.applyInfo.applyStatusName&&form.applyInfo.applyStatusName)'>{{isProject?'立项':'立案'}}</div>
              <div @click='applyCaseOrProject'>用印</div>
              <div @click='deletCase'>删除</div>
            </div>
          </myPopover>
        </div>
      </div>
      <div class="topNav">
        <div class="navItem">
          <router-link :to='isProject?"/project/main/details":"/case/main/details/"+caseId' active-class='active'>详情</router-link>
        </div>
        <div class="navItem">
          <router-link :to='isProject?"/project/main/files":"/case/main/files/"+caseId' active-class='active'>卷宗</router-link>
        </div>
        <div class="navItem">
          <router-link :to='isProject?"/project/main/finance":"/case/main/finance/"+caseId' active-class='active'>财务</router-link>
        </div>
        <div class="navItem" v-if='!isProject'>
          <router-link :to="'/case/main/tactics/'+caseId" active-class='active'>策略</router-link>
        </div>
      </div>
    </div>
    <div class="content">
      <router-view 
      :caseInfo='caseInfo'
      @upLoadCaseInfo='upLoadCaseInfo'></router-view>
    </div>
    <!-- 申请立项 -->
    <myDialog
    parentRef='isShowProject'
    @dialogAfterLeave='afterLeave("isShowProject")'
    title='申请立项'
    :isShow='isShowProjectOpp'
    :closeDialog='closeDialog'>
      <div class="projectOpp">
        <div class="itemFlex">
          <div class="title">项目名称</div>
          <div class="titleVal">上海某某公司法律顾问</div>
        </div>
        <div class="itemFlex">
          <div class="title">立项信息</div>
          <div class="titleVal">
            <div class="itemFlex">
              <div class="title">项目类型</div>
              <div class="titleVal">法律顾问</div>
            </div>
            <div class="itemFlex">
              <div class="title">委托方</div>
              <div class="titleVal">大河有限公司</div>
            </div>
            <div class="itemFlex">
              <div class="title">委托时间</div>
              <div class="titleVal">2021-01-21</div>
            </div>
          </div>
        </div>
        <div class="formBox">
          <el-form :model="projectForm" label-width="70px">
            <el-form-item label="审批人" prop="approver"
            :rules="[
              {
                required: true,  trigger: 'change'
              }
            ]">
              <el-select style='width:315px' size='small' v-model="projectForm.approver" placeholder="请选择">
                <el-option
                  v-for="item in approverList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="备注" prop="beizhu">
              <el-input style='width:315px' size='small' type='textarea' v-model="projectForm.beizhu" placeholder="请输入备注信息"></el-input>
            </el-form-item>
          </el-form>
          <div class="formBottomBtn">
            <el-button type='primary' size='small'>提交申请</el-button>
          </div>
        </div>
      </div>
    </myDialog>
    <!-- 申请立案 -->
     <myDialog
    parentRef='isShowCaseOpp'
    title='申请立案'
    :isShow='isShowCaseOpp'
    :closeDialog='closeDialog'>
      <div class="projectOpp caseOpp">
        <div class="itemFlex">
          <div class="title">案件名称</div>
          <div class="titleVal">{{form.caseName}}</div>
        </div>
        <div class="itemFlex">
          <div class="title">立案信息</div>
          <div class="titleVal">
            <div class="itemFlex">
              <div class="title">案件类型</div>
              <div class="titleVal">{{form.caseTypeName}}</div>
            </div>
            <div class="itemFlex">
              <div class="title">委托时间</div>
              <div class="titleVal">{{form.entrustDate}}</div>
            </div>
            <div class="itemFlex">
              <div class="title">我方当事人</div>
              <div class="titleVal">
                <div class="dsrBox" v-for='item in form.ourLitigantList' :key='item.holderId'>
                  <div class="name title">{{item.holderName}}</div>
                  <div class="name idntify">{{item.holderTypeName}}</div>
                  <div class="name type">{{item.partyTypeName}}</div>
                </div>
              </div>
            </div>
            <div class="itemFlex">
              <div class="title">对方当事人</div>
              <div class="titleVal">
                <div class="dsrBox" v-for='item in form.oppositeLitigantList' :key='item.holderId'>
                  <div class="name title">{{item.holderName}}</div>
                  <div class="name idntify">{{item.holderTypeName}}</div>
                  <div class="name type">{{item.partyTypeName}}</div>
                </div>
              </div>
            </div>
            <div class="itemFlex">
              <div class="title">标的</div>
              <div class="titleVal">{{form.subject}}</div>
            </div>
            <div class="itemFlex">
              <div class="title">标的金额</div>
              <div class="titleVal">{{form.subjectAmount}}元</div>
            </div>
            <div class="itemFlex">
              <div class="title">事实与理由</div>
              <div class="titleVal">{{form.factualReason}}</div>
            </div>
          </div>
        </div>
        <el-form ref='applyCaseForm'
        :model="applyCaseForm" label-width="70px">
        <el-form-item label="附件" prop="fileIdList" 
          :rules="[
            {required:true, trigger:'change',message:'附件不能为空'}
          ]">
            <el-upload :disabled='uploadLoading'
              ref='myUpload'
              class="uploadComp"
              action="#"
              :limit='5'
              :on-change="handleChangeUpload"
              :http-request='myUploadSubmit'
              :on-exceed='onExceed'
              :on-error='onErrorUpload'
              :before-remove="beforeRemove"
              accept=".png,.jpg,.jpeg,.doc,.docx,.pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
              <el-button size="small" type="primary" 
              icon="el-icon-plus" :loading="uploadLoading">上传</el-button>
              <template #tip>
                <div class="errorBox" v-show='upLoadIsError'>
                  <el-alert
                    :title="errorText"
                    type="error"
                    show-icon
                    @close='closeAlert'>
                  </el-alert>
                </div>
                <div class="textLine">
                  <div>可上传<span>5个附件</span></div>
                  <div>每个附件大小不得超过8M，支持格式：“jpg”,“png”,“doc”,“pdf”,“xls”</div>
                </div>
              </template>
            </el-upload>
        </el-form-item>
        <div class="formBox">
          <el-form-item label="审批人" prop="reviewUserId"
            :rules="[
              {
                required: true,  trigger: 'change',message:'审批人不能为空'
              }
            ]">
              <el-select style='width:315px' size='small' 
              v-model="applyCaseForm.reviewUserId"
              filterable
                remote
                reserve-keyword
                placeholder="请输入审批人"
                :remote-method="remoteMethodspr"
                :loading="sprloading">
                <el-option
                  v-for="item in reviewUserList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="备注" prop="applyRemark">
              <el-input style='width:315px' size='small' type='textarea' 
              v-model="applyCaseForm.applyRemark" placeholder="请输入备注信息"></el-input>
            </el-form-item>
          <div class="formBottomBtn">
            <el-button type='primary' size='small'
            @click='submitApplyCase'>提交申请</el-button>
          </div>
        </div>
        </el-form>
      </div>
    </myDialog>
    <!-- 项目-用印申请 -->
    <!-- <myDialog
    parentRef='isShowProjectApply'
    @dialogAfterLeave='afterLeave("isShowProjectApply")'
    title='用印申请'
    :isShow='isShowProjectApply'
    :closeDialog='closeDialog'>
      <div class="applySeal">
        <el-form :model="applySealProject" label-width="100px">
          <el-form-item label="用印事项" prop="applyTypeName" class='flexCenter'
          :rules="[
            { required:true,message:'请输入用印事项',trigger:['blur','change'] }
          ]">
            <el-input size='small' style='width:440px'
              placeholder="请输入内容"
              v-model="applySealProject.applyTypeName">
              <template #prepend>
                <el-select size='small' style='width:100px' v-model="applySealProject.applyTypeSelect" >
                  <el-option label="委托合同" value="1"></el-option>
                  <el-option label="出庭证" value="2"></el-option>
                  <el-option label="会见证" value="3"></el-option>
                </el-select>
              </template>
            </el-input>
          </el-form-item>
          <div class="inlineBox">
            <el-form-item label="印章类型" prop="applySealType" 
            :rules="[
              {required:true, trigger:'change'}
            ]">
              <el-select size='small' style='width:100px' 
              v-model="applySealProject.applySealType" >
                <el-option label="公章" value="1"></el-option>
                <el-option label="合同章" value="2"></el-option>
                <el-option label="财务章" value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="用印份数" prop="applySealNum" 
            :rules="[
              {required:true, trigger:'change'}
            ]">
              <el-input-number size='small' v-model="applySealProject.applySealNum" @change="handleChange" :min="1" :max="99"></el-input-number>
            </el-form-item>
          </div>
          <el-form-item label="审批人" prop="applySealName" 
          :rules="[
            {required:true, trigger:'change'}
          ]">
              <el-select size='small' style='width:440px' 
                v-model="applySealProject.applySealName" >
                <el-option label="张主任" value="1"></el-option>
                <el-option label="李主任" value="2"></el-option>
                <el-option label="韩主任" value="3"></el-option>
              </el-select>
          </el-form-item>
          <el-form-item label="附件" prop="fileList" 
          :rules="[
            {required:true, trigger:'change'}
          ]">
            <el-upload
              ref='myUpload'
              class="uploadComp"
              action="asd"
              multiple
              :limit='5'
              :auto-upload='false'
              :on-change="handleChangeUpload"
              :http-request='myUploadSubmit'
              :on-exceed='onExceed'
              :on-error='onErrorUpload'
              accept=".png,.jpg,.jpeg,.doc,.docx,.pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
              <el-button size="small" type="primary" 
              icon="el-icon-plus">上传</el-button>
              <template #tip>
                <div class="errorBox" v-show='upLoadIsError'>
                  <el-alert
                    :title="errorText"
                    type="error"
                    show-icon
                    @close='closeAlert'>
                  </el-alert>
                </div>
                <div class="textLine">
                  <div>可上传<span>5个附件</span></div>
                  <div>每个附件大小不得超过8M，支持格式：“jpg”,“png”,“doc”,“pdf”,“xls”</div>
                </div>
              </template>
            </el-upload>
          </el-form-item>
          <el-form-item label="备注" prop="beizhu">
            <el-input type='textarea' v-model="applySealProject.beizhu"></el-input>
          </el-form-item>
        </el-form>
        <div class="formBottomBtn">
          <el-button type='primary' plain size='small' @click='cancelApplySeal'>取消</el-button>
          <el-button type='primary' size='small' @click='submitApplySeal'>提交</el-button>
        </div>
      </div>
    </myDialog> -->
    <!-- 案件-用印申请 -->
    <myDialog
    parentRef='isShowCaseApply'
    title='用印申请'
    :isShow='isShowCaseApply'
    :closeDialog='closeDialog'>
      <div class="applySeal">
        <el-form 
        ref='applySealByCaseForm'
        :model="applySealByCaseForm" 
        label-width="100px">
          <el-form-item label="用印事项" prop="sealExtra.applyName" class='flexCenter'
          :rules="[
            { required:true,message:'请输入用印事项',trigger:['blur','change'] }
          ]">
            <el-input size='small' style='width:440px'
              placeholder="请输入内容"
              v-model="applySealByCaseForm.sealExtra.applyName">
              <template #prepend>
                <el-select size='small' style='width:100px' 
                v-model="applySealByCaseForm.sealExtra.itemType">
                  <el-option
                  v-for="item in applySealType"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key">
                </el-option>
                </el-select>
              </template>
            </el-input>
          </el-form-item>
          <div class="inlineBox">
            <el-form-item label="印章类型" prop="sealExtra.sealType" 
            :rules="[
              {required:true, trigger:'change'}
            ]">
              <el-select size='small' style='width:100px' 
              v-model="applySealByCaseForm.sealExtra.sealType" >
                <el-option
                  v-for="item in applySealType2"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="用印份数" prop="sealExtra.copyNum" 
            :rules="[
              {required:true, trigger:'change'}
            ]">
              <el-input-number size='small' 
              v-model="applySealByCaseForm.sealExtra.copyNum" 
              :min="1" :max="99"></el-input-number>
            </el-form-item>
          </div>
          <el-form-item label="审批人" prop="reviewUserId" 
          :rules="[
            {required:true, trigger:'change',message:'审批人不能为空'}
          ]">
              <el-select style='width:315px' size='small' 
              v-model="applySealByCaseForm.reviewUserId"
              filterable
                remote
                reserve-keyword
                placeholder="请输入审批人"
                :remote-method="remoteMethodspr2"
                :loading="sprloading">
                <el-option
                  v-for="item in reviewUserList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
          </el-form-item>
          <el-form-item label="附件" prop="fileIdList" 
          :rules="[
            {required:true, trigger:'change',message:'附件不能为空'}
          ]">
            <el-upload
            :disabled='uploadLoading'
              ref='myUpload2'
              class="uploadComp"
              action="#"
              :limit='5'
              :on-change="handleChangeUpload"
              :http-request='myUploadSubmit2'
              :on-exceed='onExceed'
              :on-error='onErrorUpload'
              :before-remove="beforeRemove2"
              accept=".png,.jpg,.jpeg,.doc,.docx,.pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
              <el-button :loading="uploadLoading" size="small" type="primary" 
              icon="el-icon-plus">上传</el-button>
              <template #tip>
                <div class="errorBox" v-show='upLoadIsError'>
                  <el-alert
                    :title="errorText"
                    type="error"
                    show-icon
                    @close='closeAlert'>
                  </el-alert>
                </div>
                <div class="textLine">
                  <div>可上传<span>5个附件</span></div>
                  <div>每个附件大小不得超过8M，支持格式：“jpg”,“png”,“doc”,“pdf”,“xls”</div>
                </div>
              </template>
            </el-upload>
          </el-form-item>
          <el-form-item label="备注" prop="applyRemark">
            <el-input type='textarea' v-model="applySealByCaseForm.applyRemark"></el-input>
          </el-form-item>
        </el-form>
        <div class="formBottomBtn">
          <el-button type='primary' plain size='small' @click='closeDialog("isShowCaseApply")'>取消</el-button>
          <el-button type='primary' size='small' @click='submitApplySeal("isShowCaseApply")'>提交</el-button>
        </div>
      </div>
    </myDialog>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import myPopover from '@/components/myPopover.vue';
import myDialog from '@/components/myDialog.vue';
import {getReviewUserList,uploadFile,saveApply,finish,deleteCase,} from '@/api';
export default defineComponent({
  name:"caseProjectDetail",
  components:{
    myPopover,
    myDialog
  },
  props:{
    caseId:{
      type:String
    },
    caseProjectType:{
      type:String
    },
    caseInfo:{
      type:Object,
      default:function(){
        return {}
      }
    }
  },
  async mounted(){
    if(this.caseProjectType && this.caseProjectType === 'project'){
      // 项目模板
      this.isProject = true;
    }else{
      // 默认不传为案件模板
      this.isProject = false;
      this.caseStatusOptions = await this.$store.dispatch('getDataDictionary',10009);
      this.getReviewUserListApi2();
      this.getReviewUserListApi();
      // 用印事项
      this.applySealType = await this.$store.dispatch('getDataDictionary',10021);
      // 用印类型
      this.applySealType2 = await this.$store.dispatch('getDataDictionary',10022);
    };
  },
  watch:{
    caseInfo(newV,oldV){
      this.mapFormToData(newV);
    }
  },
  data(){
    return {
      caseSelectLoading:false,
      applySealType:[],
      applySealType2:[],
      applySealByCaseForm:{
        applyRemark:'',
        applyType:1,
        fileIdList:[],
        relId:this.$route.params.caseId,
        relType:2,
        reviewUserId:'',
        sealExtra:{
          applyName:'',
          itemType:1,
          sealType:1,
          copyNum:1
        }
      },
      errorText:'',
      applyCaseForm:{
        fileIdList:[],
        reviewUserId:'',
        applyRemark:''
      },
      uploadLoading:false,
      sprloading:false,
      // 立案审核人
      getReviewUserList2:[
        {
          label:'测试写死',
          value:666
        }
      ],
      getReviewUserList:[
        {
          label:'测试写死',
          value:666
        }
      ],
      reviewUserList:[
        {
          label:'测试写死',
          value:666
        }
      ],
      upLoadIsError:false,
      isProject:false,
      caseStatusOptions:[],
      isShowProjectOpp:false,
      isShowProjectApply:false,
      isShowCaseOpp:false,
      isShowCaseApply:false,
      caseForm:{
        fileList:[],
        approver:1,
        beizhu:'',
      },
      projectForm:{
        approver:1,
        beizhu:''
      },
      approverList:[
        {
          value:1,
          label:'韩主任'
        }
      ],
      applySealProject:{
        applyTypeSelect:'1',
        applyTypeName:'',
        applySealType:'1',
        applySealNum:1,
        applySealName:'1',
        beizhu:'',
        fileList:[]
      },
      form:{}
    }
  },
  methods:{
    async deletCase(){
      let res = await deleteCase({
        caseId:this.$route.params.caseId
      });
      if(res&&res.code==200){
        this.$message({
          type:"success",
          message:'删除成功'
        });
        this.$router.replace('/case')
      }
    },
    caseStatusChange(v){
      this.finishApi({
        caseId:this.$route.params.caseId,
        caseStatus:v
      })
    },
    async finishApi(data){
      this.caseSelectLoading = true;
      let res = await finish(data);
      if(res&&res.code==200){
        this.$message({
          type:"success",
          message:"结案成功"
        })
      };
      this.caseSelectLoading = false;
    },
    handleChangeUpload(file, fileList){
      // console.log('file:',file)
    },
    myUploadSubmit(item){
      this.uploadFileApi(item.file)
    },
    myUploadSubmit2(item){
      this.uploadFileApi(item.file,2)
    },
    closeAlert(){
      console.log('closeAlert')
      this.errorText = '';
    },
    onErrorUpload(err, file, fileList){
      console.log('err:',err)
    },
    beforeRemove(file,fileList) {
      if(this.applyCaseForm.fileIdList.length === fileList.length){
        let currentUid = file.raw.uid;
        let currentIdx = fileList.filter((item,idx) => {
          if(item.raw.uid === currentUid){
            return idx
          }else{
            return 'none'
          }
        });
        if(currentIdx.length&&currentIdx[0]!=='none'){
          this.applyCaseForm.fileIdList.splice(currentIdx,1)
        }
      };
      return true
    },
    beforeRemove2(file,fileList) {
      if(this.applySealByCaseForm.fileIdList.length === fileList.length){
        let currentUid = file.raw.uid;
        let currentIdx = fileList.filter((item,idx) => {
          if(item.raw.uid === currentUid){
            return idx
          }else{
            return 'none'
          }
        });
        if(currentIdx.length&&currentIdx[0]!=='none'){
          this.applySealByCaseForm.fileIdList.splice(currentIdx,1)
        }
      };
      return true
    },
    // 文件上传
    async uploadFileApi(file,num){
      this.uploadLoading = true;
      let formData = new FormData()
      formData.append('file',file)
      if(num===2){
        formData.append("filepath",'yongyin')
      }else{
        formData.append("filepath",'lian')
      };
      let res = await uploadFile(formData);
      if(res&&res.code==200){
        if(num===2){
          this.applySealByCaseForm.fileIdList.push(res.data.fileId)
        }else{
          this.applyCaseForm.fileIdList.push(res.data.fileId)
        };
        this.$message({
          type:'success',
          message:'上传成功'
        })
      }
      this.uploadLoading = false;
    },
    onExceed(){
      // console.log('onExceed')
      this.errorText = '最多只能上传5个附件';
      this.upLoadIsError = true;
    },
    remoteMethodspr(v){
      this.sprloading = true;
      if(this.getReviewUserList2.length){
        this.reviewUserList = this.getReviewUserList2.filter(item=>{
          return item.userName.includes(v)
        });
        this.sprloading = false;
      }else{
        this.reviewUserList = [];
        this.sprloading = false;
      }
    },
    remoteMethodspr2(v){
      this.sprloading = true;
      if(this.getReviewUserList.length){
        this.reviewUserList = this.getReviewUserList.filter(item=>{
          return item.userName.includes(v)
        });
        this.sprloading = false;
      }else{
        this.reviewUserList = [];
        this.sprloading = false;
      }
    },
    // reviewType:1用印2立案3立项
    async getReviewUserListApi2(){
      let res = await getReviewUserList({
        reviewType:2
      });
      if(res&&res.code==200){
        this.getReviewUserList2 = res.data.userList
      }
    },
    async getReviewUserListApi(){
      let res = await getReviewUserList({
        reviewType:1
      });
      if(res&&res.code==200){
        this.getReviewUserList = res.data.userList
      }
    },
    upLoadCaseInfo(){
      this.$emit('upLoadCaseInfo')
    },
    applyDetils(){
      let routeData = this.$router.resolve({
        path:'/examine/details',
        query:{
          applyId:this.form.applyInfo.applyId
        }
      });
      window.open(routeData.href, '_blank');
    },
    async mapFormToData(newV){
      console.log(newV)
      this.form.applyInfo = newV.applyInfo;
      if(newV.applyInfo&&newV.applyInfo.status){
        this.form.applyInfo.applyStatusName = await this.$store.dispatch('getDictionaryName',{
          id:10009,
          status:newV.applyInfo.status
        });
      }
      // 案件名称
      this.form.caseName = newV.caseName;
      // 案号
      this.form.caseNo = newV.caseNo;
      // 状态
      this.form.caseStatus = newV.caseStatus;
      // 类型
      this.form.caseType = newV.caseType;
      this.form.caseTypeName = await this.$store.dispatch('getDictionaryName',{
        id:10003,
        status:newV.caseType
      });
      // 书记员名称
      this.form.clerkName = newV.clerkName;
      // 书记员电话
      this.form.clerkTel = newV.clerkTel;
      // 法院地址
      this.form.courtAddr = newV.courtAddr;
      // 法院案号
      this.form.courtCaseNo = newV.courtCaseNo;
      // 法院城市
      this.form.courtCity = newV.courtCity;
      // 庭审级别
      this.form.courtLevel = newV.courtLevel;
      // 法院名称
      this.form.courtName = newV.courtName;
      // 法院所属省份
      this.form.courtProvince = newV.courtProvince;
      // 法院所属区
      this.form.courtTown = newV.courtTown;
      // 案源客户id
      this.form.customerId = newV.customerId;
      // 委托日期
      this.form.entrustDate = newV.entrustDate;
      // 预估金额
      this.form.estimatedAmount = newV.estimatedAmount;
      // 事实理由
      this.form.factualReason = newV.factualReason;
      // 费用备注
      this.form.feeRemark = newV.feeRemark;
      // 收费方式
      this.form.feeType = newV.feeType;
      // 案件发生日期
      this.form.happenDate = newV.happenDate;
      // 看守所详细地址
      this.form.jailAddr = newV.jailAddr;
      // 看守所所属城市
      this.form.jailCity = newV.jailCity;
      // 看守所联系人电话
      this.form.jailContactTel = newV.jailContactTel;
      // 看守所联系人
      this.form.jailContacter = newV.jailContacter;
      // 看守所名称
      this.form.jailName = newV.jailName;
      // 看守所所属省份
      this.form.jailProvince = newV.jailProvince;
      // 看守所所属区
      this.form.jailTown = newV.jailTown;
      // 律师团队备注
      this.form.lawyerTeamRemark = newV.lawyerTeamRemark;
      // 派出所详细地址
      this.form.policeOfficeAddr = newV.policeOfficeAddr;
      // 派出所名称
      this.form.policeOfficeName = newV.policeOfficeName;
      // 派出所所属城市
      this.form.policeOfficeCity = newV.policeOfficeCity;
      // 派出所联系人
      this.form.policeOfficeContacter = newV.policeOfficeContacter;
      // 派出所所属省份
      this.form.policeOfficeProvince = newV.policeOfficeProvince;
      // 派出所联系人电话
      this.form.policeOfficeTel = newV.policeOfficeTel;
      // 派出所所属区
      this.form.policeOfficeTown = newV.policeOfficeTown;
      // 主审法官名称
      this.form.presidingJudgeName = newV.presidingJudgeName;
      // 主审法官电话
      this.form.presidingJudgeTel = newV.presidingJudgeTel;
      // 检察院详细地址
      this.form.procuratorateAddr = newV.procuratorateAddr;
      // 检察院所属城市
      this.form.procuratorateCity = newV.procuratorateCity;
      // 检察院联系人
      this.form.procuratorateContacter = newV.procuratorateContacter;
      // 检察院名称
      this.form.procuratorateName = newV.procuratorateName;
      // 检察院所属省份
      this.form.procuratorateProvince = newV.procuratorateProvince;
      // 检察院联系人电话
      this.form.procuratorateTel = newV.procuratorateTel;
      // 检察院所属区
      this.form.procuratorateTown = newV.procuratorateTown;
      // 费用预计收款日期
      this.form.feeReceiptDate = newV.feeReceiptDate;
      // 争议标的
      this.form.subject = newV.subject;
      // 标的金额
      this.form.subjectAmount = newV.subjectAmount;
      // 我方当事人
      this.form.ourLitigantList = newV.ourLitigantList;
      // 对方当事人
      this.form.oppositeLitigantList = newV.oppositeLitigantList
    
    },
    submitApplyCase(){
      this.$refs['applyCaseForm'].validate(v=>{
        if(v){
          this.saveApplyApi({
            applyRemark:this.applyCaseForm.applyRemark,
            applyType:2,
            fileIdList:this.applyCaseForm.fileIdList,
            relId:this.$route.params.caseId,
            relType:2,
            reviewUserId:this.applyCaseForm.reviewUserId
          },'isShowCaseOpp');
        }
      })
    },
    async saveApplyApi(data,type){
      let res = await saveApply(data);
      if(res&&res.code==200){
        this.closeDialog(type)
        this.$message({
          type:"success",
          message:"成功"
        })
      }
    },
    submitApplySeal(type){
      switch(type){
        // 用印申请
        case 'isShowCaseApply':
          this.$refs['applySealByCaseForm'].validate(v=>{
            if(v){
              this.saveApplyApi({
                applyRemark:this.applySealByCaseForm.applyRemark,
                applyType:this.applySealByCaseForm.applyType,
                fileIdList:this.applySealByCaseForm.fileIdList,
                relId:this.applySealByCaseForm.relId,
                relType:this.applySealByCaseForm.relType,
                reviewUserId:this.applySealByCaseForm.reviewUserId,
                sealExtra:{
                  applyName:this.applySealByCaseForm.sealExtra.applyName,
                  itemType:this.applySealByCaseForm.sealExtra.itemType,
                  sealType:this.applySealByCaseForm.sealExtra.sealType,
                  copyNum:this.applySealByCaseForm.sealExtra.copyNum
                }
              },'isShowCaseApply')
            }
          })
          break;
      };
    },

    back(){
      this.$router.replace('/case');
    },
    createCaseOrProject(){
      if(this.isProject){
        // 立项
        this.isShowProjectOpp = true;
      }else{
        // 立案
        this.isShowCaseOpp = true;
      }
    },
    applyCaseOrProject(){
      if(this.isProject){
        // 项目用印
        this.isShowProjectApply = true;
      }else{
        // 案件用印
        this.isShowCaseApply = true;
      }
    },
    afterLeave(type){
      switch(type){
        case 'isShowProject' : this.isShowProjectOpp = false; break;
        case 'isShowProjectApply' : this.isShowProjectApply = false; break;
        case 'isShowCaseOpp' : this.isShowCaseOpp = false; break;
        case 'isShowCaseApply' : this.isShowCaseApply = false; break;
      }
      // 重置表单
    },
    closeDialog(type){
      if(type){
        switch(type){
          case 'isShowProject' : 
            this.isShowProjectOpp = false; 
            break;
          case 'isShowProjectApply' : 
            this.isShowProjectApply = false; 
            break;
            // 立案申请
          case 'isShowCaseOpp' : 
            this.$refs['applyCaseForm'].resetFields();
            this.isShowCaseOpp = false; 
            break;
            // 用印申请
          case 'isShowCaseApply' : 
            this.$refs['applySealByCaseForm'].resetFields();
            this.isShowCaseApply = false; 
            break;
        };
        this.reviewUserList = [];
        this.applyCaseForm.fileIdList = [];
        this.applySealByCaseForm.fileIdList = []
      }
    }
  }
})
</script>

<style lang="scss" scoped>
@import "@/assets/style/comman.scss";
.caseProjectDetail{
  @include container;
  background:#fff;
  border-radius:20px;
  .top{
    .topBar{
      padding:20px 30px;
      border-bottom:1px solid #eee;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .titleBox{
        display: flex;
        align-items: center;
        .back{
          color:#555;
          display: flex;
          align-items: center;
          &:hover{
            cursor: pointer;
            color:$main-color
          }
          .iconlfArrow{
            font-size: 20px;
          }
          .text{
            font-size: 14px;
            margin-left:10px;
          }
        }
        .lineBar{
          width:1px;
          height:20px;
          margin:0 20px;
          border-right: 1px solid #ddd;
        }
        .title{
          display: flex;
          align-items: center;
          .titleSelect{
            ::v-deep{
              .el-input--suffix .el-input__inner{
                border-radius:30px;
                width:128px;
                padding:0 26px;
                height:36px;
              }
              .el-input__suffix{
                right:26px
              }
            }
          }
          .titleText{
            font-size: 18px;
            color:#333;
            margin:0 25px;
          }
          .titleApple{
            font-size: 12px;
            color:$main-color;
            &:hover{
              cursor: pointer;
            }
          }
        }
      }
      .btnBox{
        .icongengduo{
          font-size: 20px;
          &:hover{
            cursor: pointer;
            color:$main-color;
          }
        }
        .list{
          width:78px;
          box-sizing: border-box;
          padding:10px 0;
          div{
            width:100%;
            text-align: center;
            font-size: 14px;
            color:#555;
            padding:10px 0;
            &:hover{
              cursor: pointer;
              color: $main-color;
            }
          }
        }
      }
    }
    .topNav{
      display: flex;
      align-items: center;
      padding:20px 0;
      margin:0 30px;
      border-bottom:1px solid #eee;
      .navItem{
        margin-right: 20px;
        a{
          display: block;
          width:90px;
          height:30px;
          line-height: 30px;
          text-align: center;
          border-radius:30px;
          border:1px solid #ddd;
          text-decoration: none;
          font-size: 14px;
          color:#555;
          &:hover{
            cursor: pointer;
            color:$main-color;
            border-color:$main-color;
          }
        }
        .active{
          color:$main-color;
          border-color:$main-color;
        }
      }
    }
  }
}
.projectOpp{
  padding:30px;
  .itemFlex{
    display: flex;
    align-items: flex-start;
    margin-bottom:50px;
    .title{
      font-size: 14px;
      color:#666;
      margin-right: 14px;
    }
    .titleVal{
      font-size: 14px;
      color:#333;
      .title{
        width:65px
      }
      .itemFlex{
        margin-bottom:20px
      }
    }
  }
  .formBottomBtn{
    display: flex;
    justify-content: center;
  }
}
.applySeal{
  padding:30px;
  .flexCenter{
    ::v-deep{
      .el-form-item__label{
        height: 32px;
        line-height: 32px;
      }
    }
  }
  .inlineBox{
    display: flex;
    justify-content: space-between;
  }
  .uploadComp{
    .textLine{
      margin-top:10px;
      div{
        font-size: 12px;
        color:#666;
        height:24px;
        line-height: 24px;
        span{
          color:$main-color;
        }
      }
    }
    .errorBox{
      .el-alert{
        padding:0 8px;
      }
    }
  }
  .formBottomBtn{
    display: flex;
    justify-content: flex-end;
  }
}
.caseOpp{
  .itemFlex{
    .titleVal{
      .itemFlex{
        .title{
          width:80px;
        }
      }
    }
  }
  .dsrBox{
    display: flex;
    margin-bottom: 20px;
    .title{
      width:100px;
    }
    .idntify{
      width:40px;
      margin:0 20px;
    }
  }
  .uploadComp{
    .textLine{
      margin-top:10px;
      div{
        font-size: 12px;
        color:#666;
        height:24px;
        line-height: 24px;
        span{
          color:$main-color;
        }
      }
    }
    .errorBox{
      .el-alert{
        padding:0 8px;
      }
    }
  }
}
</style>